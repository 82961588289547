import type { SelectionOption } from '../types/SelectOption'
import GapNone from '../images/gap_none.svg'
import GapXS from '../images/gap_xs.svg'
import GapSM from '../images/gap_sm.svg'
import GapMD from '../images/gap_md.svg'
import GapLG from '../images/gap_lg.svg'
import GapXL from '../images/gap_xl.svg'

export const gapOptions: SelectionOption[] = [
  { value: 'None', label: 'None', image: GapNone },
  { value: 'Extra small', label: 'Extra small', image: GapXS },
  { value: 'Small', label: 'Small', image: GapSM },
  { value: 'Medium', label: 'Medium', image: GapMD },
  { value: 'Large', label: 'Large', image: GapLG },
  { value: 'Extra large', label: 'Extra large', image: GapXL },
]