/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react'
import { FieldAppSDK } from '@contentful/app-sdk'
import { cn } from '../utils/cn'
import { colorOptions } from '../options'

type ColorPickerOption = { name: string, value: string }

const ColorPicker = ({ sdk }: { sdk: FieldAppSDK }) => {
  const options = [...colorOptions]

  const [isOpen, setIsOpen] = useState(true)
  const [selected, setSelected] = useState<ColorPickerOption | null>(null)

  // Load contentful field value when component mounts
  useEffect(() => {
    const value = sdk.field.getValue()
    if (value) {
      const group = options.find(group => group.colors.some(color => color.value === value))
      const color = group?.colors.find(color => color.value === value)
      if (color) {
        setSelected({ name: color.label, value: color.value })
      }
    }
  }, [sdk.field])

  // Update contentful field value when selected color changes
  useEffect(() => {
    sdk.field.setValue(selected?.value ?? null)
    setIsOpen(false)
    sdk.window.updateHeight()
  }, [sdk.field, sdk.window, selected])

  /*
     To use the cma, inject it as follows.
     If it is not needed, you can remove the next line.
  */
  // const cma = useCMA();
  // If you only want to extend Contentful's default editing experience
  // reuse Contentful's editor components
  // -> https://www.contentful.com/developers/docs/extensibility/field-editors/

  return <div>
    <div className='flex items-center h-10 overflow-hidden border border-gray-200 rounded-md'>
      <button
        className='flex items-center justify-between w-full h-10 px-3'
        onClick={() => setIsOpen(!isOpen)}
      >
        {selected ? (
          <div className='flex items-center space-x-2'>
            <div
              className={cn(
                'w-4 h-4 rounded',
                selected?.name.endsWith('50') && 'border border-gray-200',
                selected?.name === 'White' && 'border border-gray-200'
              )}
              style={{ backgroundColor: selected?.value }}
            />
            <div className='space-x-1'>
              <span className='font-medium text-gray-900 font-base'>{selected?.name}</span>
              <span className='font-normal text-gray-500 font-base'>({selected?.value})</span>
            </div>
          </div>
        ) : (
          <div className='flex items-center space-x-2'>
            <div className='w-4 h-4 border border-gray-300 border-dashed rounded' />
            <div className='text-gray-500'>Select a color</div>
          </div>
        )}

        {/* Right side */}
        <div className='flex items-center space-x-4'>
          {/* Clear button */}
          {selected && <button className='flex items-center px-3 space-x-1.5 text-sm font-medium text-gray-900 border rounded h-7 hover:bg-gray-100 border-r-gray-200' onClick={() => setSelected(null)}>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="12" height="12" viewBox="0 0 48 48">
                <path d="M 39.486328 6.9785156 A 1.50015 1.50015 0 0 0 38.439453 7.4394531 L 24 21.878906 L 9.5605469 7.4394531 A 1.50015 1.50015 0 0 0 8.484375 6.984375 A 1.50015 1.50015 0 0 0 7.4394531 9.5605469 L 21.878906 24 L 7.4394531 38.439453 A 1.50015 1.50015 0 1 0 9.5605469 40.560547 L 24 26.121094 L 38.439453 40.560547 A 1.50015 1.50015 0 1 0 40.560547 38.439453 L 26.121094 24 L 40.560547 9.5605469 A 1.50015 1.50015 0 0 0 39.486328 6.9785156 z"></path>
              </svg>
            </span>
            <span className='pb-0.5'>Remove</span>
          </button>}

          {/* Chevron icon */}
          <span className={cn(isOpen && 'rotate-180')}>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0 0 32 32">
              <path d="M 4.21875 10.78125 L 2.78125 12.21875 L 15.28125 24.71875 L 16 25.40625 L 16.71875 24.71875 L 29.21875 12.21875 L 27.78125 10.78125 L 16 22.5625 Z"></path>
            </svg>
          </span>
        </div>
        
      </button>
      
    </div>
    <div className={cn('flex mx-1 pt-2', isOpen ? 'block' : 'hidden')}>
      <div className='flex items-start space-x-1.5'>
        {options.map((group, index) => (
          <div className='flex flex-col space-y-1.5'>
            {group.colors.map((option, index) => (
              <button
                className={cn('w-6 h-6 rounded', index < 2 && 'border border-gray-200')}
                style={{ backgroundColor: option.value }}
                onClick={() => setSelected({ name: option.label, value: option.value})}
                title={option.label}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  </div>
}

export default ColorPicker
