import type { SelectionOption } from '../types/SelectOption'
import PaddingSidesNone from '../images/padding_sides_none.svg'
import PaddingSidesXS from '../images/padding_sides_xs.svg'
import PaddingSidesSM from '../images/padding_sides_sm.svg'
import PaddingSidesMD from '../images/padding_sides_md.svg'
import PaddingSidesLG from '../images/padding_sides_lg.svg'
import PaddingSidesXL from '../images/padding_sides_xl.svg'

export const paddingSidesOptions: SelectionOption[] = [
  { value: 'None', label: 'None', image: PaddingSidesNone },
  { value: 'Extra small', label: 'Extra small', image: PaddingSidesXS },
  { value: 'Small', label: 'Small', image: PaddingSidesSM },
  { value: 'Medium', label: 'Medium', image: PaddingSidesMD },
  { value: 'Large', label: 'Large', image: PaddingSidesLG },
  { value: 'Extra large', label: 'Extra large', image: PaddingSidesXL },
]