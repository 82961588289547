import type { SelectionOption } from '../types/SelectOption'
import PaddingBottomNone from '../images/padding_bottom_none.svg'
import PaddingBottomXS from '../images/padding_bottom_xs.svg'
import PaddingBottomSM from '../images/padding_bottom_sm.svg'
import PaddingBottomMD from '../images/padding_bottom_md.svg'
import PaddingBottomLG from '../images/padding_bottom_lg.svg'
import PaddingBottomXL from '../images/padding_bottom_xl.svg'

export const paddingBottomOptions: SelectionOption[] = [
  { value: 'None', label: 'None', image: PaddingBottomNone },
  { value: 'Extra small', label: 'Extra small', image: PaddingBottomXS },
  { value: 'Small', label: 'Small', image: PaddingBottomSM },
  { value: 'Medium', label: 'Medium', image: PaddingBottomMD },
  { value: 'Large', label: 'Large', image: PaddingBottomLG },
  { value: 'Extra large', label: 'Extra large', image: PaddingBottomXL },
]