import type { SelectionOption } from '../types/SelectOption'
import MarginBottomNone from '../images/margin_bottom_none.svg'
import MarginBottomXS from '../images/margin_bottom_xs.svg'
import MarginBottomSM from '../images/margin_bottom_sm.svg'
import MarginBottomMD from '../images/margin_bottom_md.svg'
import MarginBottomLG from '../images/margin_bottom_lg.svg'
import MarginBottomXL from '../images/margin_bottom_xl.svg'

export const marginBottomOptions: SelectionOption[] = [
  { value: 'None', label: 'None', image: MarginBottomNone },
  { value: 'Extra small', label: 'Extra small', image: MarginBottomXS },
  { value: 'Small', label: 'Small', image: MarginBottomSM },
  { value: 'Medium', label: 'Medium', image: MarginBottomMD },
  { value: 'Large', label: 'Large', image: MarginBottomLG },
  { value: 'Extra large', label: 'Extra large', image: MarginBottomXL },
]