import type { SelectionOption } from '../types/SelectOption'
import HeightXS from '../images/height_xs.svg'
import HeightSM from '../images/height_sm.svg'
import HeightMD from '../images/height_md.svg'
import HeightLG from '../images/height_lg.svg'
import HeightXL from '../images/height_xl.svg'
import HeightDynamic from '../images/height_dynamic.svg'

export const heightOptions: SelectionOption[] = [
  { value: 'Extra small', label: 'Extra small', image: HeightXS },
  { value: 'Small', label: 'Small', image: HeightSM },
  { value: 'Medium', label: 'Medium', image: HeightMD },
  { value: 'Large', label: 'Large', image: HeightLG },
  { value: 'Extra large', label: 'Extra large', image: HeightXL },
  { value: 'Dynamic', label: 'Dynamic', image: HeightDynamic },
]