import { useEffect, useState } from 'react'
import { FieldAppSDK } from '@contentful/app-sdk'
import { cn } from '../utils/cn'
import { SelectionOption } from '../types/SelectOption'
import {
  marginTopOptions,
  marginBottomOptions,
  marginSidesOptions,
  paddingTopOptions,
  paddingBottomOptions,
  paddingSidesOptions,
  gapOptions,
  positionOptions,
  cornerRadiusOptions,
  cornerRoundedOptions,
  templateOptions,
  heightOptions
} from '../options'
import { widthOptions } from '../options/width'

type FieldTypeOptions = 'margin' | 'padding' | 'gap' | 'position' | 'corner' | 'template' | 'height'
type FieldSubtypeOptions = 'top' | 'bottom' | 'sides' | 'radius' | 'rounded' | 'N/A'

const VisualSelector = ({ sdk }: { sdk: FieldAppSDK }) => {
  const [selected, setSelected] = useState<string | boolean>(sdk.field.getValue() ?? 'None')

  useEffect(() => {
    sdk.field.setValue(selected)
    sdk.window.updateHeight()
  }, [sdk.field, sdk.window, selected])

  const type = sdk.parameters.instance.type as FieldTypeOptions
  const subtype = sdk.parameters.instance.subtype as FieldSubtypeOptions
  const options: SelectionOption[] = []

  const optionMatrix = {
    margin: {
      top: marginTopOptions,
      bottom: marginBottomOptions,
      sides: marginSidesOptions,
      radius: [],
      rounded: [],
      'N/A': [],
    },
    padding: {
      top: paddingTopOptions,
      bottom: paddingBottomOptions,
      sides: paddingSidesOptions,
      radius: [],
      rounded: [],
      'N/A': [],
    },
    gap: {
      top: [],
      bottom: [],
      sides: [],
      radius: [],
      rounded: [],
      'N/A': gapOptions,
    },
    position: {
      top: [],
      bottom: [],
      sides: [],
      radius: [],
      rounded: [],
      'N/A': positionOptions,
    },
    corner: {
      top: [],
      bottom: [],
      sides: [],
      radius: cornerRadiusOptions,
      rounded: cornerRoundedOptions,
      'N/A': [],
    },
    template: {
      top: [],
      bottom: [],
      sides: [],
      radius: [],
      rounded: [],
      'N/A': templateOptions,
    },
    height: {
      top: [],
      bottom: [],
      sides: [],
      radius: [],
      rounded: [],
      'N/A': heightOptions,
    },
    width: {
      top: [],
      bottom: [],
      sides: [],
      radius: [],
      rounded: [],
      'N/A': widthOptions,
    },
  }
  options.push(...optionMatrix[type][subtype])
  
  return <div className={cn('grid gap-4', type === 'position' ? 'grid-cols-3' : 'grid-cols-6')}>
    {options.map((option, index) => (
      <button
        type='button'
        key={index}
        className={cn('cursor-pointer rounded-md overflow-hidden', selected === option.value ? 'border border-blue-500' : 'border border-[#aec1cc]')}
        onClick={() => setSelected(option.value)}
      >
        <div className='flex items-center justify-center'>
          <img src={option.image} alt="None" />
        </div>
        <div className={cn(
          'flex items-center justify-center py-1 border-[#aec1cc] text-xs',
          selected === option.value
            ? 'border-t border-blue-500 text-blue-600 bg-blue-50 font-semibold'
            : 'border-t border-[#aec1cc] text-gray-600'
        )}>{option.label}</div>
      </button>
    ))}
  </div>
}

export default VisualSelector
