import type { SelectionOption } from '../types/SelectOption'
import PaddingTopNone from '../images/padding_top_none.svg'
import PaddingTopXS from '../images/padding_top_xs.svg'
import PaddingTopSM from '../images/padding_top_sm.svg'
import PaddingTopMD from '../images/padding_top_md.svg'
import PaddingTopLG from '../images/padding_top_lg.svg'
import PaddingTopXL from '../images/padding_top_xl.svg'

export const paddingTopOptions: SelectionOption[] = [
  { value: 'None', label: 'None', image: PaddingTopNone },
  { value: 'Extra small', label: 'Extra small', image: PaddingTopXS },
  { value: 'Small', label: 'Small', image: PaddingTopSM },
  { value: 'Medium', label: 'Medium', image: PaddingTopMD },
  { value: 'Large', label: 'Large', image: PaddingTopLG },
  { value: 'Extra large', label: 'Extra large', image: PaddingTopXL },
]