import type { SelectionOption } from '../types/SelectOption'
import TemplateOne from '../images/template_one.svg'
import TemplateTwo from '../images/template_two.svg'
import TemplateThree from '../images/template_three.svg'
import TemplateFour from '../images/template_four.svg'
import Template1focus2left from '../images/template_1focus_2left.svg'
import Template1focus2right from '../images/template_1focus_2right.svg'

export const templateOptions: SelectionOption[] = [
  { value: '1 element', label: '1 element', image: TemplateOne },
  { value: '2 elements', label: '2 elements', image: TemplateTwo },
  { value: '3 elements', label: '3 elements', image: TemplateThree },
  { value: '4 elements', label: '4 elements', image: TemplateFour },
  { value: '1 spot + 2 elements left',label: '2 + 1 (vertical)', image: Template1focus2left },
  { value: '1 spot + 2 elements right',label: '1 + 2 (vertical)', image: Template1focus2right },
]