import type { SelectionOption } from '../types/SelectOption'
import MarginSidesNone from '../images/margin_sides_none.svg'
import MarginSidesXS from '../images/margin_sides_xs.svg'
import MarginSidesSM from '../images/margin_sides_sm.svg'
import MarginSidesMD from '../images/margin_sides_md.svg'
import MarginSidesLG from '../images/margin_sides_lg.svg'
import MarginSidesXL from '../images/margin_sides_xl.svg'

export const marginSidesOptions: SelectionOption[] = [
  { value: 'None', label: 'None', image: MarginSidesNone },
  { value: 'Extra small', label: 'Extra small', image: MarginSidesXS },
  { value: 'Small', label: 'Small', image: MarginSidesSM },
  { value: 'Medium', label: 'Medium', image: MarginSidesMD },
  { value: 'Large', label: 'Large', image: MarginSidesLG },
  { value: 'Extra large', label: 'Extra large', image: MarginSidesXL },
]