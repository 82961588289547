import { FieldAppSDK } from '@contentful/app-sdk'
import { /* useCMA, */ useSDK } from '@contentful/react-apps-toolkit'
import VisualSelector from '../fields/visual-selector'
import { FieldType } from '../types/FieldType'
import ColorPicker from '../fields/color-picker'

const Field = () => {

  const sdk = useSDK<FieldAppSDK>()
  sdk.window.startAutoResizer({ absoluteElements: true })

  const field = sdk.parameters.instance.field as FieldType

  return <div className='overflow-hidden'>
    {field === 'visual-selector' && <VisualSelector sdk={sdk} />}
    {field === 'color-picker' && <ColorPicker sdk={sdk} />}
  </div>
}

export default Field
