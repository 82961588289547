export const colorOptions = [
  {
    group: 'Base',
    colors: [
      { value: '#ffffff', label: 'White' },
      { value: '#000000', label: 'Black' },
    ]
  },
  {
    group: 'Slate',
    colors: [
      { value: '#f8fafc', label: "Slate 50" },
      { value: '#f1f5f9', label: "Slate 100" },
      { value: '#e2e8f0', label: "Slate 200" },
      { value: '#cbd5e1', label: "Slate 300" },
      { value: '#94a3b8', label: "Slate 400" },
      { value: '#64748b', label: "Slate 500" },
      { value: '#475569', label: "Slate 600" },
      { value: '#334155', label: "Slate 700" },
      { value: '#1e293b', label: "Slate 800" },
      { value: '#0f172a', label: "Slate 900" },
      { value: '#020617', label: "Slate 950" },
    ]
  },
  {
    group: 'Gray',
    colors: [
      { value: '#f9fafb', label: "Gray 50" },
      { value: '#f3f4f6', label: "Gray 100" },
      { value: '#e5e7eb', label: "Gray 200" },
      { value: '#d1d5db', label: "Gray 300" },
      { value: '#9ca3af', label: "Gray 400" },
      { value: '#6b7280', label: "Gray 500" },
      { value: '#4b5563', label: "Gray 600" },
      { value: '#374151', label: "Gray 700" },
      { value: '#1f2937', label: "Gray 800" },
      { value: '#111827', label: "Gray 900" },
      { value: '#030712', label: "Gray 950" },
    ]
  },
  {
    group: 'Zinc',
    colors: [
      { value: '#fafafa', label: "Zinc 50" },
      { value: '#f4f4f5', label: "Zinc 100" },
      { value: '#e4e4e7', label: "Zinc 200" },
      { value: '#d4d4d8', label: "Zinc 300" },
      { value: '#a1a1aa', label: "Zinc 400" },
      { value: '#71717a', label: "Zinc 500" },
      { value: '#52525b', label: "Zinc 600" },
      { value: '#3f3f46', label: "Zinc 700" },
      { value: '#27272a', label: "Zinc 800" },
      { value: '#18181b', label: "Zinc 900" },
      { value: '#09090b', label: "Zinc 950" },
    ]
  },
  {
    group: 'Neutral',
    colors: [
      { value: '#fafafa', label: "Neutral 50" },
      { value: '#f5f5f5', label: "Neutral 100" },
      { value: '#e5e5e5', label: "Neutral 200" },
      { value: '#d4d4d4', label: "Neutral 300" },
      { value: '#a3a3a3', label: "Neutral 400" },
      { value: '#737373', label: "Neutral 500" },
      { value: '#525252', label: "Neutral 600" },
      { value: '#404040', label: "Neutral 700" },
      { value: '#262626', label: "Neutral 800" },
      { value: '#171717', label: "Neutral 900" },
      { value: '#0a0a0a', label: "Neutral 950" },
    ]
  },
  {
    group: 'Stone',
    colors: [
      { value: '#fafaf9', label: "Stone 50" },
      { value: '#f5f5f4', label: "Stone 100" },
      { value: '#e7e5e4', label: "Stone 200" },
      { value: '#d6d3d1', label: "Stone 300" },
      { value: '#a8a29e', label: "Stone 400" },
      { value: '#78716c', label: "Stone 500" },
      { value: '#57534e', label: "Stone 600" },
      { value: '#44403c', label: "Stone 700" },
      { value: '#292524', label: "Stone 800" },
      { value: '#1c1917', label: "Stone 900" },
      { value: '#0c0a09', label: "Stone 950" },
    ]
  },
  {
    group: 'Red',
    colors: [
      { value: '#fef2f2', label: "Red 50" },
      { value: '#fee2e2', label: "Red 100" },
      { value: '#fecaca', label: "Red 200" },
      { value: '#fca5a5', label: "Red 300" },
      { value: '#f87171', label: "Red 400" },
      { value: '#ef4444', label: "Red 500" },
      { value: '#dc2626', label: "Red 600" },
      { value: '#b91c1c', label: "Red 700" },
      { value: '#991b1b', label: "Red 800" },
      { value: '#7f1d1d', label: "Red 900" },
      { value: '#450a0a', label: "Red 950" },
    ]
  },
  {
    group: 'Orange',
    colors: [
      { value: '#fff7ed', label: "Orange 50" },
      { value: '#ffedd5', label: "Orange 100" },
      { value: '#fed7aa', label: "Orange 200" },
      { value: '#fdba74', label: "Orange 300" },
      { value: '#fb923c', label: "Orange 400" },
      { value: '#f97316', label: "Orange 500" },
      { value: '#ea580c', label: "Orange 600" },
      { value: '#c2410c', label: "Orange 700" },
      { value: '#9a3412', label: "Orange 800" },
      { value: '#7c2d12', label: "Orange 900" },
      { value: '#431407', label: "Orange 950" },
    ]
  },
  {
    group: 'Amber',
    colors: [
      { value: '#fffbeb', label: "Amber 50" },
      { value: '#fef3c7', label: "Amber 100" },
      { value: '#fde68a', label: "Amber 200" },
      { value: '#fcd34d', label: "Amber 300" },
      { value: '#fbbf24', label: "Amber 400" },
      { value: '#f59e0b', label: "Amber 500" },
      { value: '#d97706', label: "Amber 600" },
      { value: '#b45309', label: "Amber 700" },
      { value: '#92400e', label: "Amber 800" },
      { value: '#78350f', label: "Amber 900" },
      { value: '#451a03', label: "Amber 950" },
    ]
  },
  {
    group: 'Yellow',
    colors: [
      { value: '#fefce8', label: "Yellow 50" },
      { value: '#fef9c3', label: "Yellow 100" },
      { value: '#fef08a', label: "Yellow 200" },
      { value: '#fde047', label: "Yellow 300" },
      { value: '#facc15', label: "Yellow 400" },
      { value: '#eab308', label: "Yellow 500" },
      { value: '#ca8a04', label: "Yellow 600" },
      { value: '#a16207', label: "Yellow 700" },
      { value: '#854d0e', label: "Yellow 800" },
      { value: '#713f12', label: "Yellow 900" },
      { value: '#422006', label: "Yellow 950" },
    ]
  },
  {
    group: 'Lime',
    colors: [
      { value: '#f7fee7', label: "Lime 50" },
      { value: '#ecfccb', label: "Lime 100" },
      { value: '#d9f99d', label: "Lime 200" },
      { value: '#bef264', label: "Lime 300" },
      { value: '#a3e635', label: "Lime 400" },
      { value: '#84cc16', label: "Lime 500" },
      { value: '#65a30d', label: "Lime 600" },
      { value: '#4d7c0f', label: "Lime 700" },
      { value: '#3f6212', label: "Lime 800" },
      { value: '#365314', label: "Lime 900" },
      { value: '#1a2e05', label: "Lime 950" },
    ]
  },
  {
    group: 'Green',
    colors: [
      { value: '#f0fdf4', label: "Green 50" },
      { value: '#dcfce7', label: "Green 100" },
      { value: '#bbf7d0', label: "Green 200" },
      { value: '#86efac', label: "Green 300" },
      { value: '#4ade80', label: "Green 400" },
      { value: '#22c55e', label: "Green 500" },
      { value: '#16a34a', label: "Green 600" },
      { value: '#15803d', label: "Green 700" },
      { value: '#166534', label: "Green 800" },
      { value: '#14532d', label: "Green 900" },
      { value: '#052e16', label: "Green 950" },
    ]
  },
  {
    group: 'Emerald',
    colors: [
      { value: '#ecfdf5', label: "Emerald 50" },
      { value: '#d1fae5', label: "Emerald 100" },
      { value: '#a7f3d0', label: "Emerald 200" },
      { value: '#6ee7b7', label: "Emerald 300" },
      { value: '#34d399', label: "Emerald 400" },
      { value: '#10b981', label: "Emerald 500" },
      { value: '#059669', label: "Emerald 600" },
      { value: '#047857', label: "Emerald 700" },
      { value: '#065f46', label: "Emerald 800" },
      { value: '#064e3b', label: "Emerald 900" },
      { value: '#022c22', label: "Emerald 950" },
    ]
  },
  {
    group: 'Teal',
    colors: [
      { value: '#f0fdfa', label: "Teal 50" },
      { value: '#ccfbf1', label: "Teal 100" },
      { value: '#99f6e4', label: "Teal 200" },
      { value: '#5eead4', label: "Teal 300" },
      { value: '#2dd4bf', label: "Teal 400" },
      { value: '#14b8a6', label: "Teal 500" },
      { value: '#0d9488', label: "Teal 600" },
      { value: '#0f766e', label: "Teal 700" },
      { value: '#115e59', label: "Teal 800" },
      { value: '#134e4a', label: "Teal 900" },
      { value: '#042f2e', label: "Teal 950" },
    ]
  },
  {
    group: 'Cyan',
    colors: [
      { value: '#ecfeff', label: "Cyan 50" },
      { value: '#cffafe', label: "Cyan 100" },
      { value: '#a5f3fc', label: "Cyan 200" },
      { value: '#67e8f9', label: "Cyan 300" },
      { value: '#22d3ee', label: "Cyan 400" },
      { value: '#06b6d4', label: "Cyan 500" },
      { value: '#0891b2', label: "Cyan 600" },
      { value: '#0e7490', label: "Cyan 700" },
      { value: '#155e75', label: "Cyan 800" },
      { value: '#164e63', label: "Cyan 900" },
      { value: '#083344', label: "Cyan 950" },
    ]
  },
  {
    group: 'Sky',
    colors: [
      { value: '#f0f9ff', label: "Sky 50" },
      { value: '#e0f2fe', label: "Sky 100" },
      { value: '#bae6fd', label: "Sky 200" },
      { value: '#7dd3fc', label: "Sky 300" },
      { value: '#38bdf8', label: "Sky 400" },
      { value: '#0ea5e9', label: "Sky 500" },
      { value: '#0284c7', label: "Sky 600" },
      { value: '#0369a1', label: "Sky 700" },
      { value: '#075985', label: "Sky 800" },
      { value: '#0c4a6e', label: "Sky 900" },
      { value: '#082f49', label: "Sky 950" },
    ]
  },
  {
    group: 'Blue',
    colors: [
      { value: '#eff6ff', label: "Blue 50" },
      { value: '#dbeafe', label: "Blue 100" },
      { value: '#bfdbfe', label: "Blue 200" },
      { value: '#93c5fd', label: "Blue 300" },
      { value: '#60a5fa', label: "Blue 400" },
      { value: '#3b82f6', label: "Blue 500" },
      { value: '#2563eb', label: "Blue 600" },
      { value: '#1d4ed8', label: "Blue 700" },
      { value: '#1e40af', label: "Blue 800" },
      { value: '#1e3a8a', label: "Blue 900" },
      { value: '#172554', label: "Blue 950" },
    ]
  },
  {
    group: 'Indigo',
    colors: [
      { value: '#eef2ff', label: "Indigo 50" },
      { value: '#e0e7ff', label: "Indigo 100" },
      { value: '#c7d2fe', label: "Indigo 200" },
      { value: '#a5b4fc', label: "Indigo 300" },
      { value: '#818cf8', label: "Indigo 400" },
      { value: '#6366f1', label: "Indigo 500" },
      { value: '#4f46e5', label: "Indigo 600" },
      { value: '#4338ca', label: "Indigo 700" },
      { value: '#3730a3', label: "Indigo 800" },
      { value: '#312e81', label: "Indigo 900" },
      { value: '#1e1b4b', label: "Indigo 950" },
    ]
  },
  {
    group: 'Violet',
    colors: [
      { value: '#f5f3ff', label: "Violet 50" },
      { value: '#ede9fe', label: "Violet 100" },
      { value: '#ddd6fe', label: "Violet 200" },
      { value: '#c4b5fd', label: "Violet 300" },
      { value: '#a78bfa', label: "Violet 400" },
      { value: '#8b5cf6', label: "Violet 500" },
      { value: '#7c3aed', label: "Violet 600" },
      { value: '#6d28d9', label: "Violet 700" },
      { value: '#5b21b6', label: "Violet 800" },
      { value: '#4c1d95', label: "Violet 900" },
      { value: '#2e1065', label: "Violet 950" },
    ]
  },
  {
    group: 'Purple',
    colors: [
      { value: '#faf5ff', label: "Purple 50" },
      { value: '#f3e8ff', label: "Purple 100" },
      { value: '#e9d5ff', label: "Purple 200" },
      { value: '#d8b4fe', label: "Purple 300" },
      { value: '#c084fc', label: "Purple 400" },
      { value: '#a855f7', label: "Purple 500" },
      { value: '#9333ea', label: "Purple 600" },
      { value: '#7e22ce', label: "Purple 700" },
      { value: '#6b21a8', label: "Purple 800" },
      { value: '#581c87', label: "Purple 900" },
      { value: '#3b0764', label: "Purple 950" },
    ]
  },
  {
    group: 'Fuchsia',
    colors: [
      { value: '#fdf4ff', label: "Fuchsia 50" },
      { value: '#fae8ff', label: "Fuchsia 100" },
      { value: '#f5d0fe', label: "Fuchsia 200" },
      { value: '#f0abfc', label: "Fuchsia 300" },
      { value: '#e879f9', label: "Fuchsia 400" },
      { value: '#d946ef', label: "Fuchsia 500" },
      { value: '#c026d3', label: "Fuchsia 600" },
      { value: '#a21caf', label: "Fuchsia 700" },
      { value: '#86198f', label: "Fuchsia 800" },
      { value: '#701a75', label: "Fuchsia 900" },
      { value: '#4a044e', label: "Fuchsia 950" },
    ]
  },
  {
    group: 'Pink',
    colors: [
      { value: '#fdf2f8', label: "Pink 50" },
      { value: '#fce7f3', label: "Pink 100" },
      { value: '#fbcfe8', label: "Pink 200" },
      { value: '#f9a8d4', label: "Pink 300" },
      { value: '#f472b6', label: "Pink 400" },
      { value: '#ec4899', label: "Pink 500" },
      { value: '#db2777', label: "Pink 600" },
      { value: '#be185d', label: "Pink 700" },
      { value: '#9d174d', label: "Pink 800" },
      { value: '#831843', label: "Pink 900" },
      { value: '#500724', label: "Pink 950" },
    ]
  },
  {
    group: 'Rose',
    colors: [
      { value: '#fff1f2', label: "Rose 50" },
      { value: '#ffe4e6', label: "Rose 100" },
      { value: '#fecdd3', label: "Rose 200" },
      { value: '#fda4af', label: "Rose 300" },
      { value: '#fb7185', label: "Rose 400" },
      { value: '#f43f5e', label: "Rose 500" },
      { value: '#e11d48', label: "Rose 600" },
      { value: '#be123c', label: "Rose 700" },
      { value: '#9f1239', label: "Rose 800" },
      { value: '#881337', label: "Rose 900" },
      { value: '#4c0519', label: "Rose 950" },
    ]
  },
]