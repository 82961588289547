import type { SelectionOption } from '../types/SelectOption'
import MarginTopNone from '../images/margin_top_none.svg'
import MarginTopXS from '../images/margin_top_xs.svg'
import MarginTopSM from '../images/margin_top_sm.svg'
import MarginTopMD from '../images/margin_top_md.svg'
import MarginTopLG from '../images/margin_top_lg.svg'
import MarginTopXL from '../images/margin_top_xl.svg'

export const marginTopOptions: SelectionOption[] = [
  { value: 'None', label: 'None', image: MarginTopNone },
  { value: 'Extra small', label: 'Extra small', image: MarginTopXS },
  { value: 'Small', label: 'Small', image: MarginTopSM },
  { value: 'Medium', label: 'Medium', image: MarginTopMD },
  { value: 'Large', label: 'Large', image: MarginTopLG },
  { value: 'Extra large', label: 'Extra large', image: MarginTopXL },
]