import type { SelectionOption } from '../types/SelectOption'
import CornerRadiusNone from '../images/corner_radius_none.svg'
import CornerRadiusXS from '../images/corner_radius_xs.svg'
import CornerRadiusSM from '../images/corner_radius_sm.svg'
import CornerRadiusMD from '../images/corner_radius_md.svg'
import CornerRadiusLG from '../images/corner_radius_lg.svg'
import CornerRadiusXL from '../images/corner_radius_xl.svg'

export const cornerRadiusOptions: SelectionOption[] = [
  { value: 'None', label: 'None', image: CornerRadiusNone },
  { value: 'Extra small', label: 'Extra small', image: CornerRadiusXS },
  { value: 'Small', label: 'Small', image: CornerRadiusSM },
  { value: 'Medium', label: 'Medium', image: CornerRadiusMD },
  { value: 'Large', label: 'Large', image: CornerRadiusLG },
  { value: 'Extra large', label: 'Extra large', image: CornerRadiusXL },
]