import type { SelectionOption } from '../types/SelectOption'
import PositionTopLeft from '../images/position_top_left.svg'
import PositionTopCenter from '../images/position_top_center.svg'
import PositionTopRight from '../images/position_top_right.svg'
import PositionCenterLeft from '../images/position_center_left.svg'
import PositionCenterCenter from '../images/position_center_center.svg'
import PositionCenterRight from '../images/position_center_right.svg'
import PositionBottomLeft from '../images/position_bottom_left.svg'
import PositionBottomCenter from '../images/position_bottom_center.svg'
import PositionBottomRight from '../images/position_bottom_right.svg'

export const positionOptions: SelectionOption[] = [
  { value: 'top-left', label: 'Top left', image: PositionTopLeft },
  { value: 'top-center', label: 'Top center', image: PositionTopCenter },
  { value: 'top-right', label: 'Top right', image: PositionTopRight },
  { value: 'center-left', label: 'Center left', image: PositionCenterLeft },
  { value: 'center-center', label: 'Center', image: PositionCenterCenter },
  { value: 'center-right', label: 'Center right', image: PositionCenterRight },
  { value: 'bottom-left', label: 'Bottom left', image: PositionBottomLeft },
  { value: 'bottom-center', label: 'Bottom center', image: PositionBottomCenter },
  { value: 'bottom-right', label: 'Bottom right', image: PositionBottomRight },
]