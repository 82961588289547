import type { SelectionOption } from '../types/SelectOption'
import WidthXS from '../images/width_xs.svg'
import WidthSM from '../images/width_sm.svg'
import WidthMD from '../images/width_md.svg'
import WidthLG from '../images/width_lg.svg'
import WidthXL from '../images/width_xl.svg'
import HeightFull from '../images/width_full.svg'

export const widthOptions: SelectionOption[] = [
  { value: 'Extra small', label: 'Extra small', image: WidthXS },
  { value: 'Small', label: 'Small', image: WidthSM },
  { value: 'Medium', label: 'Medium', image: WidthMD },
  { value: 'Large', label: 'Large', image: WidthLG },
  { value: 'Extra large', label: 'Extra large', image: WidthXL },
  { value: 'Full', label: 'Full width', image: HeightFull },
]